.footer {
  position: relative;
  padding: 64px;
  background: hsl(0deg, 0%, 5%);
  color: white;
  text-align: center;
}

.footer a {
  color: inherit;
}
