.characterEditor {
  padding-top: 128px;
  padding-bottom: 64px;
}

.header {
  width: 65%;
  padding-bottom: 64px;
  position: relative;
}

.title {
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 16px;
  letter-spacing: -0.5px;
}

.description {
  font-size: 1.25rem;
  margin: 0;
}

.characterWrapper {
  position: fixed;
  top: 15%;
  left: 60%;
  bottom: 5%;
  right: 150px;
  min-height: 500px;
}

.controlColumn {
  width: 50%;
  position: relative;
}

.bottomBackground {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 40%;
  background-color: hsl(195deg, 20%, 86%);
}
